'use strict';

var ajax =  require('./ajax'),
	util = require('./util'),
	_ = require('lodash'),
	progress = require('./progress'),
	dialog = require('./dialog'),
	shippingData =  require('./pages/product/delivery'),
	bannerSmartCustomer = require('./bannerSmartCustomer'),
	delivery = require('./pages/product/delivery'),
    headerMenu = require('./headermenu');

var location = {
    latitude: '',
    longitude: '',
    plusCode: '',
    isVerified: false
};

function initializeRegionsComunasSmartCustomer() {
	window.uiSelect.init();

	const $selectLocationRegions = $('#location-region-select');
	const $selectLocationCommunes = $('#location-comuna-select');
	const $saveButton = $('#location-region-comuna-save').length ? $('#location-region-comuna-save') : $('#btn-unregistered-user-continue');

    $saveButton.attr('aria-label', window.Resources.COMUNASOURCE_ARIALABEL_DISABLED);

	$selectLocationRegions.on('change', function(event) {
		if (regionsComunas == undefined) return;
		
		var currentSelector = event.target;
		var uniqueId = currentSelector.value;
		const comunasList = regionsComunas[uniqueId].sort();
		var isNewRegion = false;
		var regionName = $(this).find(':selected').text();
		
		// clear comunas select
		$selectLocationCommunes.find('option[value!=""]').remove();
		
		if (comunasList) {

			$.each(comunasList, function (i, item) {
				$selectLocationCommunes.append($('<option>', {
					value: item,
					text : item
				}));
			});
		}
	});

	$selectLocationCommunes.on('change', function(event) {
		if(!_.isEmpty($('#dialog-container #region-comuna-modal #location-comuna-select').val())){
			$('#dialog-container #region-comuna-modal #location-region-comuna-save').addClass('active');
            $saveButton.removeAttr('aria-label');
		} else {
			$('#dialog-container #region-comuna-modal #location-region-comuna-save').removeClass('active');
            $saveButton.attr('aria-label', window.Resources.COMUNASOURCE_ARIALABEL_DISABLED);
		}
	});

	let smartCustomerRegion = localStorage.getItem('smart_customer_region');
	let hasSavedData = false;

	try {
		smartCustomerRegion = JSON.parse(smartCustomerRegion);
		hasSavedData = true;
	} catch(e) {
		// console.error(e);
	}

	if (hasSavedData) {
		if (!_.isEmpty(smartCustomerRegion)) {
			if (smartCustomerRegion.addressId) {
				$('.address-list input[name="address"]').prop('checked', false);
				$(`.address-list input[value="${smartCustomerRegion.addressId}"`).prop('checked', true);
				$('#button-collapse-1').removeClass('hidden');
				$('#content-collapse-1').addClass('hidden');
				activateSaveButton($saveButton);
			} else {
				$('#button-collapse-1').addClass('hidden');
				$('#content-collapse-1').removeClass('hidden');
				fillRegionsComunasSmartCustomer(smartCustomerRegion);
			}
		} else {
			$('#button-collapse-1').removeClass('hidden');
			$('#content-collapse-1').addClass('hidden');
		}
	}

	const $items = $('.address-list-container .address-list .address-tile');
    
	if ($items.length > 3) {
		$items.each((i, item) => {
			if (i < 3) return;
			$(item).hide();
		});
	} else {
		$('#toggle-addresses').hide();
	}

	$('#toggle-addresses').on('click', function(e) {
		e.preventDefault();
		if ($(this).text().trim() !== 'Ver todas') {
			$items.each((i, item) => {
				if (i < 3) return;
				$(item).hide();
			});
			$(this).text('Ver todas');
		} else {
			$items.show();
			$(this).text('Ver menos');
		}
	});

	$('#button-collapse-1').on('click', () => {
		$('#button-collapse-1').addClass('hidden');
		$('#content-collapse-1').removeClass('hidden');
		$('#location-region-comuna-save').removeClass('active');
        $saveButton.attr('aria-label', 'Para guardar tu ubicación, selecciona región y comuna');
		if ($('input[name="address"]:checked')) {
			$('input[name="address"]:checked').prop('checked', false);
		}

		if ($items.length > 3) {
			$items.each((i, item) => {
				if (i < 3) return;
				$(item).hide();
			});
			$('#toggle-addresses').text('Ver todas');
		}
	});

	$('input[name="address"]').on('change', (e) => {
		activateSaveButton($saveButton);
		document.getElementById('button-collapse-1').classList.remove('hidden');
		document.getElementById('content-collapse-1').classList.add('hidden');
		window.uiSelect.setValue($selectLocationRegions, null);
		window.uiSelect.setValue($selectLocationCommunes, null);
		//colapsar las direcciones
	});

	const handleBtnClick = function(e) {
		const $addressChecked = $('input[name="address"]:checked');
		const $latandlng = $addressChecked.data('latandlng');
		const $addressformatted = $addressChecked.data('addressformatted');
		const $url_main_address = $('#url_main_address').val();
		let comuna = $selectLocationCommunes.val();
		let regionObject = $selectLocationRegions.find(':selected').data('value');
		let comunaObject = {};
        let geometryAddress = {
            latitude: '',
            longitude: '',
            plusCode: '',
            isVerified: false,
            saveLatLong: false,
        }
		if (!_.isEmpty(comuna) && !_.isEmpty(regionObject)) {
            comunaObject = regionObject.comunas.find(function(comunaItem) {
                if (comunaItem.name === comuna) {
                    return comunaItem;
				}
			});
			if (!_.isEmpty(comunaObject)) {
                const customer_address_str = localStorage.getItem('smart_customer_region');
                const customer_address = !_.isEmpty(customer_address_str) ? JSON.parse(customer_address_str) : null;
                let smartCustomerRegion = {
                    addressId: null,
                    uniqueId: regionObject.uniqueId,
                    id: regionObject.id,
                    name: regionObject.name,
                    comuna: comunaObject,
                    geometry: null
                };
                if (!_.isEmpty(customer_address)) {
                    if (!_.isEmpty(customer_address.geometry)) {
                        if ( _.isEmpty(customer_address.geometry.latitude) || _.isEmpty(customer_address.geometry.longitude)) {
                            getLatAndLngByComunaName(smartCustomerRegion, $saveButton);
                        } else if (customer_address.comuna.name !== comuna) {
                            getLatAndLngByComunaName(smartCustomerRegion, $saveButton);
                        } else {
                            getLatAndLngByComunaName(smartCustomerRegion, $saveButton);
                        }
                    } else {
                        getLatAndLngByComunaName(smartCustomerRegion, $saveButton);
                    }
                } else {
                    getLatAndLngByComunaName(smartCustomerRegion, $saveButton);
                }
			}
		} else if (!_.isEmpty($addressChecked) && !_.isEmpty($url_main_address)) {
            if (!_.isEmpty($addressformatted) && !$latandlng.includesLatAndLong) {
                sendAddressToValidate($addressformatted, false).then(function (response) {
                    geometryAddress.latitude = location.latitude;
                    geometryAddress.longitude = location.longitude;
                    geometryAddress.plusCode = location.plusCode;
                    geometryAddress.isVerified = location.isVerified;
                    geometryAddress.saveLatLong = true;
                    savePreferredAddress($addressChecked, $url_main_address, $saveButton, geometryAddress);
                });
            } else {
                geometryAddress.latitude = isLatitude($latandlng.latitude) ? $latandlng.latitude : '';
                geometryAddress.longitude = isLongitude($latandlng.longitude) ? $latandlng.longitude : '';
                geometryAddress.plusCode = $latandlng.plusCode;
                geometryAddress.isVerified = $latandlng.isVerified;
                savePreferredAddress($addressChecked, $url_main_address, $saveButton, geometryAddress);
            }

		}
	};

	$saveButton.off('click', handleBtnClick);
    $saveButton.on('click', handleBtnClick);
    
    const $elements = $('.select-ui__inner');
    $elements.each((i, el) => {
        $(el).on('keydown', (e) => {
            if (e.keyCode === 32 || e.keyCode === 13) {
                const selected = $(el)[0].selectedIndex;
                $(el).prop('selectedIndex', selected);
            }
        });
    });

    const $dialogModal = $('.ui-dialog-comuna-source');
    $dialogModal.on('keyup', (e) => {
        if (e.keyCode === 27) {
            e.preventDefault();
            dialog.close();
        };
    });

    $dialogModal.focus();

    function activateSaveButton(saveButton) {
        saveButton.addClass('active');
        saveButton.removeAttr('aria-label');
    }
}


function savePreferredAddress (addressChecked, url_main_address, saveButton, geometryAddress) {
    const comunaId = addressChecked.data('comuna-id');
    const idAddress = addressChecked.data('id-address');
    const addressIdUrl = addressChecked.val().replace(' ', '%20');
    let url = `${url_main_address}?addressId=${addressIdUrl}&latitude=${geometryAddress.latitude}&longitude=${geometryAddress.longitude}&plusCode=${geometryAddress.plusCode}&isVerified=${geometryAddress.isVerified}&saveLatLong=${geometryAddress.saveLatLong}&comunaId=${comunaId}&format=ajax`;
    saveButton.addClass('btn--loading').empty();
    saveButton.append($('<div></div>'));
    saveButton.append($('<div></div>'));
    saveButton.append($('<div></div>'));

    $.ajax({
    type: 'GET',
    url: url,
    success: function (response) {
            if(!_.isEmpty(response)) {
                const isComunaSameDayDelivery = response.data.isComunaSameDayDelivery || false;
                const regionid = addressChecked.data('regionid');
                const regionsData = JSON.parse($('#sc_regions').val());
                const regionObj = regionsData.find(item => item.newRegionId == regionid);
                const addressId = addressChecked.val();
                const comuna_address = addressChecked.data('comuna');
                let addressGeometry = {};
                
                if (!geometryAddress.saveLatLong) {
                    const savedData = JSON.parse(localStorage.getItem('smart_customer_region'));
                    if (!_.isEmpty(savedData) && !_.isEmpty(savedData.addressId && !_.isEmpty(idAddress)) && savedData.addressId.replace(/\s+/g, '').toLocaleLowerCase() === idAddress.toLocaleLowerCase()) {
                        addressGeometry = savedData.geometry;
                    } else {
                        addressGeometry = geometryAddress;
                    }
                }
                if(!_.isEmpty(regionObj)) {
                    let comunaObject = regionObj.comunas.find(function(comunaItem) {
                        if (comunaItem.name === comuna_address) {
                                return comunaItem;
                        }
                    });

                    if(!_.isEmpty(addressId) && !_.isEmpty(comunaObject)) { 
                        const smartCustomerRegionAddress = {
                            addressId,
                            uniqueId: regionObj.uniqueId,
                            id: regionObj.id,
                            name: regionObj.name,
                            comuna: comunaObject,
                            geometry: (geometryAddress.saveLatLong) ? geometryAddress : addressGeometry,
                            sameDay: isComunaSameDayDelivery
                        };
                        delete smartCustomerRegionAddress.geometry.saveLatLong;
                        saveLocalStorage(smartCustomerRegionAddress, comuna_address);
                    }
                }
				
                if (!isComunaSameDayDelivery) {
                    showHideSameDayFilter('none');
                } else {
                    showHideSameDayFilter('block');
                }
            }
        },
    failure: function () {
    //TODO: definir que hacer aca...
        }
    });
}

function saveNewLatAndLong(customerAddresses) {
    $.ajax({
        type: 'POST',
        url: window.Urls.setLatitudLongitud,
        data: {
            addressess: JSON.stringify(customerAddresses)
        },
        success: function (response) {
            localStorage.removeItem('adressesLAtLng');
            return;
        },
    failure: function () {
        localStorage.removeItem('adressesLAtLng');
        return;
    //TODO: definir que hacer aca...
        }
    });
}

function getLatAndLngByComunaName (smartCustomerRegion, saveButton) {
    const comunaName = !_.isEmpty(smartCustomerRegion) ? smartCustomerRegion.comuna.name : '';
    let url = `${window.Urls.getLatAndLngByComunaName}?comunaName=${comunaName}&format=ajax`;
    saveButton.addClass('btn--loading').empty();
    saveButton.append($('<div></div>'));
    saveButton.append($('<div></div>'));
    saveButton.append($('<div></div>'));

    $.ajax({
    type: 'GET',
    url: url,
    success: function (response) {
                
        let locationByComuna = {
            latitude: '',
            longitude: '',
            plusCode: '',
            isVerified: false,
            saveLatLong: false,
        }
        if (!_.isEmpty(response.data.locationByComuna)) {
            locationByComuna.latitude  = response.data.locationByComuna.location.latitude;
            locationByComuna.longitude  = response.data.locationByComuna.location.longitude;
        }
        smartCustomerRegion.geometry = locationByComuna;
        if(!_.isEmpty(smartCustomerRegion)) {
            const isComunaSameDay = checkIfComunaIdIsAvailableForSameDay(smartCustomerRegion) || false;
            smartCustomerRegion.sameDay = isComunaSameDay;
            saveLocalStorage(smartCustomerRegion, comunaName);
            if (isComunaSameDay) {
                showHideSameDayFilter('block');
            } else {
                showHideSameDayFilter('none');
            }
            // headerMenu.SetComunaNameDualRun(true);
        }
    },
    failure: function () {
            return
        }
    });
}

function setRegionAndComunaPersonalDetails(smartCustomerRegion) {
    const comunaName = !_.isEmpty(smartCustomerRegion) ? smartCustomerRegion.comuna.name : '';
    let url = `${window.Urls.getLatAndLngByComunaName}?comunaName=${comunaName}&format=ajax`;
    let locationByComuna = {
        latitude: '',
        longitude: '',
        plusCode: '',
        isVerified: false,
        saveLatLong: true,
    }
    $.ajax({
    type: 'GET',
    url: url,
    success: function (response) {
        locationByComuna.latitude  = response.data.locationByComuna.location.latitude;
        locationByComuna.longitude  = response.data.locationByComuna.location.longitude;
        smartCustomerRegion.geometry = locationByComuna;
        if(!_.isEmpty(smartCustomerRegion)) {
            smartCustomerRegion.sameDay =  checkIfComunaIdIsAvailableForSameDay(smartCustomerRegion) || false;
            const smartCustomerRegionStr = JSON.stringify(smartCustomerRegion);
            localStorage.setItem('smart_customer_region', smartCustomerRegionStr);
            if(window.hasOwnProperty('listenerLevelService')){
                window.listenerLevelService();
            }
        }
    },
    failure: function () {
            return
        }
    });
    const customerRegionStr = localStorage.getItem('smart_customer_region');
    if (_.isEmpty(customerRegionStr)) {
        smartCustomerRegion.geometry = locationByComuna;
        const smartCustomerStr = JSON.stringify(smartCustomerRegion);
        localStorage.setItem('smart_customer_region', smartCustomerStr);
        if(window.hasOwnProperty('listenerLevelService')){
            window.listenerLevelService();
        }
    }
}

function saveLocalStorage(smartCustomerRegion, comunaName){
	const savedData = localStorage.getItem('smart_customer_region');
    const savedDataBefore = !_.isEmpty(savedData);
	const address = JSON.stringify(smartCustomerRegion);
	const email = $('#smartCustomerEmail').val();
	const tag = `${smartCustomerRegion.name} - ${smartCustomerRegion.comuna.name}`;
    localStorage.setItem('smart_customer_region', address);
    if(window.hasOwnProperty('listenerLevelService')){
        window.listenerLevelService();
    }
    // trigger storage event listener
    dispatchEvent(new Event('storage'));

	bannerSmartCustomer.loadImage();
    //
    delivery.init(); 
    //
    $("#dialog-container").dialog('close');
    $('#header-comuna-source-popup').removeClass('header-comuna-source__popup--open');
    $('#header-comuna-source-p1').text(window.Resources.COMUNASOURCE_HEADER_ENTRGEAR_EN);
    $('#header-comuna-source-p2').text(comunaName.toLocaleLowerCase()).css('text-transform', 'capitalize');
    $('#refinement-location__text').text(comunaName.toLocaleLowerCase()).css('text-transform', 'capitalize');
    if($('#delivery-info-comuna-name').length){

        $('#delivery-info-comuna-name').each(function(){
            $(this).text(smartCustomerRegion.comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
        }); 
    }
    $('#header-comuna-source-mobile-btn span').html(`${window.Resources.COMUNASOURCE_HEADER_ENTRGEAR_EN} <b>${comunaName.toLocaleLowerCase()}</b>`);

    if (savedDataBefore) {
        window.uiNotification('success', window.Resources.COMUNASOURCE_NOTIFICATION_CHANGED_TITLE, window.Resources.COMUNASOURCE_NOTIFICATION_TEXT);

        window.dataLayer.push({
            'event-interactive-category': 'Agregar comuna y región',
            'event-interactive-accion': 'Modificación',
            'event-interactive-tag': tag,
            'event': 'event-interactive'
        });
    
        analyticsIdentityUser();
    } else {
        window.uiNotification('success', window.Resources.COMUNASOURCE_NOTIFICATION_TITLE, window.Resources.COMUNASOURCE_NOTIFICATION_TEXT);

        window.dataLayer.push({
            'event-interactive-category': 'Agregar comuna y región',
            'event-interactive-accion': 'Datos ingresados',
            'event-interactive-tag': tag,
            'event': 'event-interactive'
        });
        analyticsIdentityUser();
    }

    if(!_.isEmpty(email)) {
        $.ajax({
            type: 'POST',
            url: window.Urls.smartCustomerAddressURL,
            data: {
                smartCustomerAddress: address,
                smartCustomerEmail: email
            }
        });
    }

    $('#smartCustomerAddress').val(address);
    cleanSameDayRefinements();
}

function cleanSameDayRefinements() {
    let sameDayRefinementElement;
    if (document.querySelector("div.refinement.communesAvailableForSameDay > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")) {
        $("#sameday-delivery-filter-refine-bar").prop("checked", false);
        sameDayRefinementElement = document.querySelector("#communesAvailableForSameDay > li.selected > :is(a.js-refinement-item, span.js-refinement-item)");
        if(sameDayRefinementElement){
            sameDayRefinementElement.click();
        }
    }
}

function fillRegionsComunasSmartCustomer(data) {
	const $selectLocationRegions = $('#location-region-select');
	const $selectLocationCommunes = $('#location-comuna-select');

	const regionId = data.uniqueId;
	const comunaName = data.comuna.name;
	// $selectLocationRegions.selectUiValue(regionId);
	// $selectLocationCommunes.selectUiValue(comunaName);
	window.uiSelect.setValue($selectLocationRegions, regionId);
	window.uiSelect.setValue($selectLocationCommunes, comunaName);
}

function fillRegionsComunasPersonalDetails(data) {
	const $personalDetailsRegions = $('#dwfrm_personaldetails_region');
	const $personalDetailsCommunes = $('#dwfrm_personaldetails_comuna');

	const regionId = data.uniqueId;
	const comunaName = data.comuna.name;

	window.uiSelect.setValue($personalDetailsRegions, regionId);
	window.uiSelect.setValue($personalDetailsCommunes, comunaName);
}

function initializeRegionsComunaFiltering() {
	$('.js-region-select').on('change', function(event) {
		if (regionsComunas == undefined) return;
		
		var currentSelector = event.target;
		var regionId = currentSelector.value;
		var data = [];
		var comunasList = [];
		var isNewRegion = false;
		var regionName = $(this).find(':selected').text()
		$("#asset-product-available").hide();
		$("#asset-product-unavailable").hide();
		
		// clear comunas select
		$('.js-comuna-select').find('option[value!=""]').remove();
		
		//if region is 10-11-12-14 for example take their own comunas
    if (newRegionsComunas[regionName] != undefined) {
            comunasList = newRegionsComunas[regionName];
            isNewRegion = true;
        
    }
		
    if (regionsComunas[regionId] !== undefined) {
            comunasList = isNewRegion ? comunasList : regionsComunas[regionId];
            //Remove new Region comunas from old region comunas (because currently they are merged)
            if (regionToRegion && regionToRegion[regionName] != undefined) {
                var newComunaList = [];
                var comunasOfNewRegion = newRegionsComunas[regionToRegion[regionName]];
                for (var com in comunasList) {
                    if (!comunasOfNewRegion.includes(comunasList[com])) {
                        newComunaList.push(comunasList[com]);
                    }
                }
                comunasList = newComunaList;
            }

			$.each(comunasList, function (i, item) {
				$('.js-comuna-select').append($('<option>', {
					value: item,
					text : item
				}));
			});
		}
	});
}

function initializeDPDFiltering(){
	$('.js-departamento-select').on('change', function(event) {
		if (provincesList == undefined) return;
		var currentSelector = event.target;
		var departmentId = currentSelector.value;
		$('.js-provincia-select').find('option[value!=""]').remove();

		$.each(provincesList[departmentId], function (i, item) {
			$('.js-provincia-select').append($('<option>', {
				value: item.nombre_ubigeo,
				text : item.nombre_ubigeo
			}));
		});
	});

	$('.js-provincia-select').on('change', function(event) {
		if (districtsList == undefined) return;
		var currentSelector = event.target;
		var provinciaId = currentSelector.value;
		$('.js-distrito-select').find('option[value!=""]').remove();

		$.each(districtsList[provinciaId], function (i, item) {
			$('.js-distrito-select').append($('<option>', {
				value: item.nombre_ubigeo,
				text : item.nombre_ubigeo
			}));
		});
	});

}

function initializeAvaiabilityCheck() {
	$('.js-comuna-select').on('change', function(event) {
		var currentSelector = $(event.target);
		var productid = currentSelector.attr('data-product');
		var region = $('.js-region-select').val();
		var comuna =  event.target.value;
		$("#asset-product-available").hide();
		$("#asset-product-unavailable").hide();
		
		progress.show($("#asset-product-check-container"));
		
		// init ajax call
		ajax.getJson({
			url: util.appendParamsToUrl(Urls.checkDeliveryAvailability, {
				region: region,
				productid: productid,
				comuna: comuna
			}),
			callback: function(data) {
				if ((data != undefined) || data !== null) {
					if (data.success) {
						$("#asset-product-available").show();
					} else {
						$("#asset-product-unavailable").show();
					}
				}
				progress.hide();
			}
		});
	});
}

function analyticsIdentityUser() {
	var smartCustomerRegion = localStorage.getItem('smart_customer_region');
    const smartCustomerGARut = $('#smartCustomerRut').val();
	const smartCustomerGALoginTag = $('#smartCustomerLoginTag').val();
	const smartCustomerGALoginType = $('#smartCustomerLoginType').val();
	var smartCustomerGARegion = '';
	var smartCustomerGAComuna = '';

	if(!_.isEmpty(smartCustomerRegion)) {
		smartCustomerRegion = JSON.parse(smartCustomerRegion);
		smartCustomerGARegion = smartCustomerRegion.name;
        smartCustomerGAComuna = smartCustomerRegion.comuna.name;
	}

	var gaObject = new Object();
	gaObject['event-interactive-category'] = 'Identificación usuario';
	gaObject['event-interactive-accion'] = 'Modificación';
	gaObject['event-interactive-tag'] = smartCustomerGALoginTag;
	
	if(!_.isEmpty(smartCustomerGARut)) {
		gaObject['event-interactive-rut'] = smartCustomerGARut;
	}
	
	if(!_.isEmpty(smartCustomerGALoginType)) {
		gaObject['event-interactive-tipoLogin'] = smartCustomerGALoginType;
	}
	
	if (!_.isEmpty(smartCustomerGARegion)) {
		gaObject['event-interactive-region'] = smartCustomerGARegion;
	}

	if(document.getElementById('gamificationLevel')) {
    gaObject['event-interactive-nivel'] = document.getElementById('gamificationLevel').value;
  }
	
	if (!_.isEmpty(smartCustomerGAComuna)) {
		gaObject['event-interactive-comuna'] = smartCustomerGAComuna;
	}
	
	gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
	window.dataLayer.push(gaObject);
}

function sendAddressToValidate(address, zeroResults){	
    if (zeroResults === undefined) {
        zeroResults = false;
    }
    if (_.isEmpty(address)) {
        return false;
    }

    if (typeof(window.google) !== 'undefined') {
        var service = new google.maps.places.PlacesService(document.createElement('div'));
    }
    const request = {
        query: address,
        fields: ['formatted_address', 'place_id', 'geometry', 'plus_code'],
    };
    return new Promise(function(resolve, reject) {
        service.findPlaceFromQuery(request, function(results, status){
            cleanLocation();
            resolve(validateAddressWithGoogleCallBack(results, status, address.toLowerCase(), zeroResults));
        });
    }); 
}

function validateAddressWithGoogleCallBack(results,status, address, zeroResults){
	if (status === google.maps.places.PlacesServiceStatus.OK) {
		if((results[0].formatted_address.toLowerCase() == address || (address.includes('avenida') && address.replace('avenida','av.') == results[0].formatted_address.toLowerCase()))
        || (address.includes('doctor') && address.replace('doctor','dr.') == results[0].formatted_address.toLowerCase()) && !zeroResults){
			/* if it's the same place, save shipping and continue */
            // direccion verificada
			// saveShippingAddress();
             // persistir lat y long y plus code
            location.latitude = results[0].geometry.location.lat();
            location.longitude = results[0].geometry.location.lng();
            location.plusCode = results[0].hasOwnProperty('plus_code') ? results[0].plus_code.global_code : '';
            location.isVerified = true;
		}
		else {
			/* else display the map*/
            location.latitude = results[0].geometry.location.lat();
            location.longitude = results[0].geometry.location.lng();
            location.plusCode = results[0].hasOwnProperty('plus_code') ? results[0].plus_code.global_code : '';
            location.isVerified = false;
		}
	} else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        const $addressChecked = $('input[name="address"]:checked');
        const $addressObject = $addressChecked.data();
        const minAddress = $addressObject.comuna + ',' + $addressObject.region + ',' + 'Chile';
        sendAddressToValidate(minAddress, true).then();
	} else {
        return location;
	}
    return location;
}

function sendAddressGetLatAndLong(customerAddress, zeroResults){
    if (zeroResults === undefined) {
        zeroResults = false;
    }
    if (_.isEmpty(customerAddress.addressFormatted) || _.isEmpty(customerAddress.miniAddress)) {
        return false;
    }

    if (typeof(window.google) !== 'undefined') {
        var service = new google.maps.places.PlacesService(document.createElement('div'));
    }

    const address = (zeroResults) ? customerAddress.miniAddress : customerAddress.addressFormatted;
    const request = {
        query: address,
        fields: ['formatted_address', 'place_id', 'geometry', 'plus_code'],
    };
    return new Promise(function(resolve, reject) {
        service.findPlaceFromQuery(request, function(results, status){
            resolve(validateAddresses(results, status, customerAddress, zeroResults));
        });
    }); 
}

function validateAddresses(results,status, customerAddress, zeroResults){
    let customerLocation = {
        latitude: '',
        longitude: '',
        plusCode:'',
        isVerified: false
    }
	if (status === google.maps.places.PlacesServiceStatus.OK) {
        const address = customerAddress.addressFormatted;
		if((results[0].formatted_address.toLowerCase() == address.toLowerCase() || (address.includes('avenida') && address.replace('avenida','av.') == results[0].formatted_address.toLowerCase()))
        || (address.includes('doctor') && address.replace('doctor','dr.') == results[0].formatted_address.toLowerCase()) && !zeroResults){
            customerLocation.latitude = results[0].geometry.location.lat();
            customerLocation.longitude = results[0].geometry.location.lng();
            customerLocation.plusCode = results[0].hasOwnProperty('plus_code') ? results[0].plus_code.global_code : '';
            customerLocation.isVerified = true;
		}
		else {
            customerLocation.latitude = results[0].geometry.location.lat();
            customerLocation.longitude = results[0].geometry.location.lng();
            customerLocation.plusCode = results[0].hasOwnProperty('plus_code') ? results[0].plus_code.global_code : '';
            customerLocation.isVerified = false;
		}
	} else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        const miniAddress = customerAddress.miniAddress;
        const addressesUpdated = [];
        sendAddressGetLatAndLong(miniAddress, true).then(function(response) {
            addressesUpdated.push(response);
        });
	} else {
        return customerLocation;
    }

    return customerLocation;
}

function cleanLocation() {
        location.latitude = '';
        location.longitude = '';
        location.plusCode = '';
        location.isVerified = false;
}

function checkLatLongCustomerAddress() {
    $( window ).on( 'load', function() {
        const adressesLAtLngStr = localStorage.getItem('adressesLAtLng') || '';
        const adressesLAtLng = (adressesLAtLngStr !== '') ? JSON.parse(adressesLAtLngStr) : [];
        if (adressesLAtLng.length > 0) {
            const addressess = JSON.parse($('#addresses').val());
            const customerAddresses = !_.isEmpty(addressess) ? addressess : [];
            const addressesUpdated = [];
            if (_.isEmpty(customerAddresses)) { 
                return false;
            }   
            if (customerAddresses.length) {
                for (let i = 0, p = Promise.resolve(); i < customerAddresses.length; i++) {
                    if (_.isEmpty(customerAddresses[i].latitude) || _.isEmpty(customerAddresses[i].longitude)) {
                        p = p.then(function(){
                            sendAddressGetLatAndLong(customerAddresses[i], false).then(function(address) {
                                addressesUpdated.push({
                                    ID: customerAddresses[i].ID,
                                    latitude: address.latitude,
                                    longitude: address.longitude,
                                    plusCode: address.plusCode,
                                    isVerified: address.isVerified
                                });
                            }).finally(function(res) {
                                const addressesToLocalStorage = JSON.stringify(addressesUpdated);
                                localStorage.setItem('adressesLAtLng', addressesToLocalStorage);
                            });
                        }).then(function(i) {
                        });
                    }
                }
            }
        }
    });
    
    $('.js-customer-addresses').on('click',function() {
        const adressesLAtLng_str = localStorage.getItem('adressesLAtLng');
        const customer_address = (adressesLAtLng_str !== '') ? JSON.parse(adressesLAtLng_str) : [];
        if (customer_address.length > 0) {
            saveNewLatAndLong(customer_address);
        }
    });
}

function checkIfComunaIdIsAvailableForSameDay(smartCustomerRegion) {
    const comunasSameDayStr = $('#comunasSameDay').val();
    const comunasSameDay = (!_.isEmpty(comunasSameDayStr)) ? JSON.parse(comunasSameDayStr) : [];
    let isComunaSameDay = false;
    let comunaId = (!_.isEmpty(smartCustomerRegion.comuna.id)) ? smartCustomerRegion.comuna.id : 0;
    if (comunasSameDay && comunasSameDay.length) {
        isComunaSameDay = comunasSameDay.some(function(comunaSameDay) {
            return parseInt(comunaSameDay, 10) === parseInt(comunaId, 10);
        });
    }
    return isComunaSameDay;
}

function showHideSameDayFilter(displayAction) {
    const elements = document.getElementsByClassName('refinement-sameday-delivery');
    const elementIsSameDayDelivery = document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay");
	
    if (elementIsSameDayDelivery === null || elementIsSameDayDelivery === undefined) {
        
        for (var i=0; i<elements.length; i++) {
            elements[i].style.display = 'none';
        }
        
        if(document.querySelector("div.refinement-content > div.refinement-sameday-delivery")){
            document.querySelector("div.refinement-content > div.refinement-sameday-delivery").style.display = 'none';
        } 
        return;
    }

    if (!_.isEmpty(elements)) {
        for (let i=0; i<elements.length; i++) {
            elements[i].style.display = displayAction;
        }
        if(document.querySelector("div.refinement-content > div.refinement-sameday-delivery")){
            document.querySelector("div.refinement-content > div.refinement-sameday-delivery").style.display = displayAction;
        }  
    }
}
function initPersonalDetailRegionsComunas() {
	window.uiSelect.init();

	const $personalDetailsRegions = $('#dwfrm_personaldetails_region');
	const $personalDetailsCommunes = $('#dwfrm_personaldetails_comuna');
	const $BtnUnregisteredUserContinue = $('#btn-unregistered-user-continue');
    const $regionsDataStr = $('#sc_regions_pd').val();
    const $regionsData = !_.isEmpty($regionsDataStr) ? JSON.parse($regionsDataStr) : null;
    $BtnUnregisteredUserContinue.attr('aria-label', window.Resources.COMUNASOURCE_ARIALABEL_DISABLED);
	$personalDetailsRegions.on('change', function(event) {
		if (regionsComunas == undefined) return;
		
		var currentSelector = event.target;
        var uniqueId = currentSelector.value;
        const comunasList = regionsComunas[uniqueId].sort();
		var isNewRegion = false;
		var regionName = $(this).find(':selected').text();

		
        // clear comunas select
		$personalDetailsCommunes.find('option[value!=""]').remove();
		
		if (comunasList) {

			$.each(comunasList, function (i, item) {
				$personalDetailsCommunes.append($('<option>', {
					value: item,
					text : item
				}));
			});
		}
	});

	const smartCustomerRegionStr = localStorage.getItem('smart_customer_region');
    const smartCustomerRegionObj = !_.isEmpty(smartCustomerRegionStr) ? JSON.parse(smartCustomerRegionStr) : null;
    if (!_.isEmpty(smartCustomerRegionObj)) {
        fillRegionsComunasPersonalDetails(smartCustomerRegionObj);
    }

	const btnContinue = function(e) {

        this.classList.add('btn--loading');
        this.getElementsByTagName('span')[0].classList.add('hidden');
        let loaderDivs = this.getElementsByClassName('loading');
        for (let i = 0; i < loaderDivs.length; i++) {
            const loaderDiv = loaderDivs[i];
            loaderDiv.classList.remove('hidden');
        }

		const currentRegion = $personalDetailsRegions.find(':selected').data('value');
		const regionAndComunas = $regionsData.find(function(regionData) {
            if (regionData.newRegionId === currentRegion.newRegionId) {
                return regionData;
            }
        });

		let comuna = $personalDetailsCommunes.val();
		let comunaObject = {};
		if (!_.isEmpty(comuna) && !_.isEmpty(regionAndComunas)) {
            comunaObject = regionAndComunas.comunas.find(function(comunaItem) {
                if (comunaItem.name === comuna) {
                    return comunaItem;
				}
			});
			if (!_.isEmpty(comunaObject)) {
                let smartCustomerRegion = {
                    addressId: null,
                    uniqueId: regionAndComunas.uniqueId,
                    id: regionAndComunas.id,
                    name: regionAndComunas.name,
                    comuna: comunaObject,
                    geometry: null
                };
                setRegionAndComunaPersonalDetails(smartCustomerRegion);
			}
		} 
	};
    
	$BtnUnregisteredUserContinue.off('click', btnContinue);
    $BtnUnregisteredUserContinue.on('click', btnContinue);
    
    const $elements = $('.select-ui__inner');
    $elements.each((i, el) => {
        $(el).on('keydown', (e) => {
            if (e.keyCode === 32 || e.keyCode === 13) {
                const selected = $(el)[0].selectedIndex;
                $(el).prop('selectedIndex', selected);
            }
        });
    });

    const $dialogModal = $('.ui-dialog-comuna-source');
    $dialogModal.on('keyup', (e) => {
        if (e.keyCode === 27) {
            e.preventDefault();
            dialog.close();
        };
    });

    $dialogModal.focus();
}

function showHidePickupFilter(displayAction) {
    const elements = document.getElementsByClassName('refinement-pickup-delivery');
    const elementIsPickUpDelivery = document.querySelector("div.refinement-content > div.refinement.pickUp");
	
    if (elementIsPickUpDelivery === null || elementIsPickUpDelivery === undefined) {
        
        for (var i=0; i<elements.length; i++) {
            elements[i].style.display = 'none';
        }
        
        if(document.querySelector("div.refinement-content > div.refinement-pickup-delivery")){
            document.querySelector("div.refinement-content > div.refinement-pickup-delivery").style.display = 'none';
        } 
        return;
    }

    if (!_.isEmpty(elements)) {
        for (let i=0; i<elements.length; i++) {
            elements[i].style.display = displayAction;
        }
        if(document.querySelector("div.refinement-content > div.refinement-pickup-delivery")){
            document.querySelector("div.refinement-content > div.refinement-pickup-delivery").style.display = displayAction;
        }  
    }
}

function isLatitude(lat) {
    if (!lat) {
        return false;
    }
    return isFinite(lat) && Math.abs(lat) <= 90;
}

function isLongitude(lng) {
    if (!lng) {
        return false;
    }
    return isFinite(lng) && Math.abs(lng) <= 180;
}

function initializeEvents() {
	initializeRegionsComunaFiltering();
	initializeDPDFiltering();
	initializeAvaiabilityCheck();
    checkLatLongCustomerAddress();
}

exports.init = initializeEvents;
exports.initializeRegionsComunaFiltering = initializeRegionsComunaFiltering;
exports.initializeDPDFiltering = initializeDPDFiltering;
exports.initializeRegionsComunasSmartCustomer = initializeRegionsComunasSmartCustomer;
exports.fillRegionsComunasSmartCustomer = fillRegionsComunasSmartCustomer;
exports.initPersonalDetailRegionsComunas = initPersonalDetailRegionsComunas;